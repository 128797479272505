import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';

export interface Dropdown {
  value: string;
  disable: boolean;
  search: boolean;
  placeholder: string;
  checked: boolean;
  isAllSelect: boolean;
  selectDropdownCount: boolean;
  dropdownList: Array<{
    id: number;
    value: string;
    checked: boolean;
  }>;
  showValues?: boolean;
}

@Component({
  name: 'MultiSelectDropdown',
})
export default class MultiSelectDropdown extends Vue {

   public openDropdown: boolean = false;
   public search: string = '';
   public dropdownElement : any = {};
   public active : number = -1;
   public counter : number = 0;
   public selectedRecords: {
    id: number;
    value: string;
    checked: boolean;
  }[] = [];
   public fewDataChecked: string = '';
   public tabPress: boolean=false;
   public enterPress: boolean=false;
   public selectedPosition: number = 0;
   public cursorIndex: number = 0;
public  previousSelectedValues:any=[];

   @Prop({ default: { id: 0, value: 'Select', label: '', required:false,  error: false, errorText: '', search: '', checked: false,disable:false, isAllSelect: true, selectDropdownCount: true,component:'', dropdownList: [{ id: 0, value: 'Select', checked: false,key:''}] } })
   dropdown!: { id: number; value: string; label?: string; required?:boolean;  error?: boolean; errorText?: string; disable?: boolean; search?: boolean; checked: boolean; isAllSelect: boolean; selectDropdownCount: boolean;component?:string;key?:string; dropdownList: [{ id: number; value: string; checked: boolean ;isSelectedByOtherUserRole?:boolean }]; showValues?: boolean };

   @Prop() 
   dropdownId!:any;

   /* istanbul ignore next */
   get isSelectAll() {
     return this.selectedRecords.length > 0;
   }

   get displayValue(): string {
     if (!this.selectedRecords.length) {
       return this.dropdown.value;
     }
     return this.dropdown.showValues
       ? this.selectedRecords.map(({ value }) => value).join(', ')
       : this.selectedRecords[0].value;
   }

   @Watch('dropdown', { deep: true, immediate: true })
   dropdownSelectedRecords(dropdownValues:any){
     this.selectedRecords = [];
     this.dropdown && this.dropdown.dropdownList && this.dropdown.dropdownList.length && this.dropdown.dropdownList.forEach((element: any) => {
       if (element.checked) {
         this.selectedRecords.push(element);
       }
     });
     if (this.selectedRecords && this.selectedRecords.length && this.selectedRecords.length !== this.dropdown.dropdownList.length) {
       this.fewDataChecked = 'select-action-checkbox';
       this.dropdown.isAllSelect = false;
     }
     else {
       this.fewDataChecked = '';
       this.dropdown.isAllSelect = true;
     }
   }
   
   /* istanbul ignore next */
   mounted() {
     // let checkedSites:any = [];
     this.dropdown && this.dropdown.dropdownList && this.dropdown.dropdownList.length && this.dropdown.dropdownList.forEach((element: any) => {
       if (element.checked) {
         this.selectedRecords.push(element);
       }
     });
     this.halfSelection();
   }

   toggleDropdown(event? : any){
     event.preventDefault();
      
     const previousDropdownState = this.openDropdown;
     if(event && event.keyCode == 13){
       this.openDropdown = !this.openDropdown;
       const ele = document.getElementById(this._uid.toString());
       ele &&  ele.focus();
     }
     else if(event && (event.keyCode==32 || event.keyCode==38 || event.keyCode==40) && !this.openDropdown){
       this.openDropdown = true;
        
     }
     else if(event.type == 'click'){
       this.openDropdown = !this.openDropdown;
     }
     if(!previousDropdownState){
       this.active = -1;
       this.counter = 1;
       setTimeout(()=>{
         this.toggleSearchBoxFocus(true);
       },50);
     }
  
     if(this.dropdown && this.dropdown.component == APP_CONST.USER && !this.openDropdown && !this.dropdown.error){
       this.$emit('selectionRecords', { isAllSelect: APP_CONST.FALSE, dropdownList: this.dropdown.dropdownList,selectedValue:this.dropdown.dropdownList });
     }
     if(this.openDropdown == false){
       if(!(this.matchArrays(this.previousSelectedValues.map((x:any)=>x.id), this.selectedRecords.map((x:any)=>x.id)))) {
         this.$emit('multiSelectDropdownClosed');
       }
     }
     if(this.openDropdown) {
       this.previousSelectedValues = [...this.selectedRecords];
     }
   }

   filterList(list: any, search: string): any {
     if (!search || search.trim().length == 0) {
       return list;
     }
     let dropList = list.filter((el: any) => el.value.toLowerCase().includes(search.toLowerCase()));
     if(!dropList.length){
       dropList = [];
     }
     return dropList;
   }

   clearSearch() {
     this.search = '';
   }

   searchSites(event?:any){
     this.search = event && event.target.value;
   }

   selectAll($event: any) {
     if ($event.target.checked) {
       this.dropdown && this.dropdown.dropdownList && this.dropdown.dropdownList.forEach((element: any) => {
         element.checked = $event.target.checked;
         this.selectedRecords.push(element);
       });
     }
     else {
       this.dropdown && this.dropdown.dropdownList && this.dropdown.dropdownList.forEach((element: any) => {
         element.checked = $event.target.checked;
       });
       this.selectedRecords = [];
     }
     this.dropdown.isAllSelect = true;
      
     this.halfSelection(this.dropdown.dropdownList);
   }

   selectParticular(obj: any) {
     const ele:any = document.getElementById(this._uid.toString());
     if(ele) {
       ele.focus();
     }
     const index = this.selectedRecords.findIndex((element: any) => element.id === obj.id);
     const indexDropdown = this.dropdown.dropdownList.findIndex((element: any) => element.id === obj.id);
     if (this.dropdown.dropdownList[indexDropdown]) {
       this.dropdown.dropdownList[indexDropdown].checked = obj.checked;
     }
     if (index == -1) {
       this.selectedRecords.push(obj);
     }
     else {
       this.selectedRecords.splice(index, 1);
     }
     this.halfSelection(obj);
   }

   halfSelection(obj?:any) {
     if (this.selectedRecords && this.selectedRecords.length && this.selectedRecords.length !== this.dropdown.dropdownList.length) {
       this.fewDataChecked = 'select-action-checkbox';
       this.dropdown.isAllSelect = false;
     }
     else {
       this.fewDataChecked = '';
       this.dropdown.isAllSelect = true;
     }
     this.$emit('selectionRecords', { isAllSelect: this.dropdown.isAllSelect, dropdownList: this.dropdown.dropdownList,selectedValue:obj});
   }

   hoverState(type:string, index:number ){
     const selectAllElement = document.querySelector('#select-all');
     if(type= 'listItem'){
       const dropdownMenuItems = this.dropdownElement && this.dropdownElement.children;
       this.active = index;
       if(this.active && this.active>-1) {
         dropdownMenuItems && dropdownMenuItems[this.active] && dropdownMenuItems[this.active].classList.add('dropbtn-selected');
       }
       dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
         if (index != this.active){
           dropdownMenuItems && dropdownMenuItems[index] && dropdownMenuItems[index].classList.remove('dropbtn-selected');
         }
       });
       selectAllElement && selectAllElement.classList.remove('dropbtn-selected');
     }
     else if(type == 'selectAll'){
       selectAllElement && selectAllElement.classList.add('dropbtn-selected');
       const dropdownMenuItems = this.dropdownElement.children;
       this.active = index;
       dropdownMenuItems && dropdownMenuItems.forEach((drop: any, index: number) => {
         if (index != this.active && this.active >-1) {
           dropdownMenuItems[index] && dropdownMenuItems[index].classList.remove('dropbtn-selected');
         }
       });
     }
   }

   onDropdownOpen(e:any,column?:any){
     e.stopPropagation();
     const handleTabEvents = (dropdownItems?:any)=>{
       if(e.shiftKey && activeElement!==searchBoxItem && this.dropdown.search) {
         e.preventDefault();
         this.toggleSearchBoxFocus(true, dropdownItems);
       }
       else if(!e.shiftKey && activeElement==searchBoxItem && this.dropdown.search){
         e.preventDefault();
         this.toggleSearchBoxFocus(false,dropdownItems);
       }
       else{
         this.openDropdown=false;
         this.search='';
       }
     };

     
     const activeElement:any = document.activeElement;
     const searchBoxItem:any = document.getElementById(this._uid+'_searchInput');
     if(e && e.keyCode == APP_CONST.KEY_ESCAPE){
       this.openDropdown = false;
       const ele = document.getElementById(this._uid.toString());
       ele &&  ele.focus();
       return;
     }
     if(activeElement==searchBoxItem && e.keyCode !== APP_CONST.KEY_TAB) {
       return;
     }
     const dropdownItems = [];
     const elements:any = document.getElementById('dropdown-menu_'+this._uid.toString());
      
     const listItems:any = elements && [...elements.children];
     const displayAllItem:any = document.getElementById(this._uid+'_select-all');
     dropdownItems.push(displayAllItem);
     if(listItems){
       listItems.forEach((element:any) => {
         dropdownItems.push(element);
       });
     }
    
     switch(e.keyCode) {
       case APP_CONST.KEY_TAB:
         handleTabEvents(dropdownItems);
         break;
       case APP_CONST.KEY_ENTER:
         break;
       case APP_CONST.KEY_ESCAPE:
         break;
       case APP_CONST.KEY_SPACE:
         if(activeElement && activeElement.type !=='search') {
           this.selectDropdownItem(dropdownItems, e);
           return;
         }
         break;
       case APP_CONST.KEY_ARROW_DOWN:
         e.preventDefault();
         if(++this.cursorIndex>dropdownItems.length-1) {
           this.cursorIndex=dropdownItems.length-1;
         }
         this.highlightAndFocusItem(dropdownItems);
         break;
       case APP_CONST.KEY_ARROW_UP:
         e.preventDefault();
         if(--this.cursorIndex <0) {
           this.cursorIndex=0;
         }
         this.highlightAndFocusItem(dropdownItems);
         break;
       default:
         if(!(e.ctrlKey || e.altKey || e.shiftKey)) {
           const keyValue = String.fromCharCode(e.keyCode).match(/(\w|\s)/g);
             
           if(keyValue && activeElement && activeElement.type !=='search') {
             this.navigateToDropdownItemByInitials(dropdownItems, keyValue[0]);
           }
         }              
         break;
     }
   } 
   highlightAndFocusItem(dropdownItems:any){
     dropdownItems[this.selectedPosition] && dropdownItems[this.selectedPosition].classList.remove('dropbtn-selected');
     this.selectedPosition= Math.abs(this.cursorIndex%dropdownItems.length);
     if(dropdownItems[this.selectedPosition].type!=='search') {
       dropdownItems[this.selectedPosition].classList.add('dropbtn-selected');
     }
     dropdownItems[this.selectedPosition].focus();
     dropdownItems[this.selectedPosition].scrollIntoView({block:'nearest'});
   }

   selectDropdownItem(dropdownItems:any,event:any){
     event.preventDefault();
     dropdownItems[this.selectedPosition] && dropdownItems[this.selectedPosition].querySelector('input').click();
     dropdownItems[this.selectedPosition] && dropdownItems[this.selectedPosition].focus();
     dropdownItems[this.selectedPosition] && dropdownItems[this.selectedPosition].scrollIntoView({block:'nearest'});
   }
 
   navigateToDropdownItemByInitials(dropdownItems:any, keyValue:string){
     let iterationCount:number = 1;
     let i:number = this.cursorIndex;
 
     while(iterationCount!=dropdownItems.length){
       if(++i==dropdownItems.length) {
         i=0;
       }
       const val = dropdownItems[i].innerText.charAt(0).toLowerCase();
       const inp = keyValue.toLowerCase();
       if(val == inp){
         this.cursorIndex=i;
         this.highlightAndFocusItem(dropdownItems);
         break;
       }              
       iterationCount++;
     }     
   }
   toggleSearchBoxFocus(shouldFocus:boolean, dropdownItems?:any){
     try{
       let ele:any = document.getElementById(this._uid+'_searchInput');
       if(shouldFocus && this.dropdown.search) {
         const ele:any = document.getElementById(this._uid+'_searchInput');
         ele &&  ele.focus();
         dropdownItems && dropdownItems[this.selectedPosition].classList.remove('dropbtn-selected');
       }
       else {
         this.cursorIndex=-1;
         ele = document.getElementById(this._uid.toString());
         ele.focus();
         this.selectedPosition=0;
         this.cursorIndex=0;
         if(dropdownItems){
           this.highlightAndFocusItem(dropdownItems);
         }
       }
     }
     catch(e) {
       console.error(e);
     }
   }


   resetScrollToTop(){
     const elem:any  = document.getElementById('reset-scroll') as HTMLElement ;
     elem.scrollTo(0,0);
   }

   outsideClose(){
     if(this.openDropdown){
       if(!(this.matchArrays(this.previousSelectedValues.map((x:any)=>x.id), this.selectedRecords.map((x:any)=>x.id)))) {
         this.$emit('multiSelectDropdownClosed');
       }
       this.openDropdown = false;
       this.search = '';
     }
   }

   matchArrays(first:any, second:any) {
     return first.length === second.length && first.find((v:any,i:any) => v !== second[i]) === undefined;
   }

}